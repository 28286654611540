<template>
  <div class="historgam"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.historgam');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              if (item.seriesName === '时长') {
                let min = `${(item.value % 60).toFixed(0)}`;
                let h = `${Math.floor(item.value / 60)}`;
                str = str.concat(`${item.marker + item.seriesName}: ${h}小时${min}分钟<br/>`);
              } else {
                str = str.concat(`${item.marker + item.seriesName}: ${item.value}次<br/>`);
              }
            });
            return str;
          }
        },
        grid: {
          top: '5%',
          right: '5%',
          left: '5%',
          bottom: '20%'
        },
        legend: {
          data: ['次数', '时长'],
          bottom: '1%',
          left: '5%',
          itemWidth: 13,
          itemHeight: 13,
          itemGap: 30
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: this.ChartsData.xAxisValues
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '次数',
            min: 0,
            position: 'left',
            axisLabel: {
              formatter: '{value}'
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            name: '时长',
            min: 0,
            position: 'right',
            offset: 0,
            axisLabel: {
              formatter: '{value}'
            }

            // splitLine: {
            //   show: false
            // }
          }
        ],
        series: [
          {
            name: '次数',
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            barWidth: '18px',
            data: this.ChartsData.totalNumber,
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '时长',
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            yAxisIndex: 1,
            data: this.ChartsData.totalTime,
            barWidth: '18px',
            itemStyle: {
              color: '#ea3837',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.historgam {
  height: 600px;
}
</style>
